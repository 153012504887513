import React from 'react';
import cn from 'classnames';

import Button, { ButtonShape, ButtonVariant } from '@components/Button';
import Modal from '@components/Modal';
import { Typography } from '@components/Typography';
import { BtnsDirection, ConfirmationModalProps } from './ConfirmationModal.types';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  btnsDirection = BtnsDirection.COL,
  children,
  classNames,
  header,
  description,
  descriptionNote,
  onClose,
  primaryBtnDisabled,
  primaryBtnLabel,
  primaryBtnOnClick,
  primaryBtnIcon,
  primaryBtnVariant = ButtonVariant.PRIMARY_ONE,
  secondaryBtnLabel,
  secondaryBtnOnClick,
  secondaryBtnIcon,
  secondaryBtnDisabled,
  secondaryBtnVariant = ButtonVariant.NO_FILL,
  show,
  hideSecondaryButton,
  subText,
  haveCloseButton = true,
  hidePrimaryButton = false,
  isMediumScreen,
  errorMessage,
  maxHeight,
}) => {
  const isBtnDirectionCol = btnsDirection === BtnsDirection.COL;
  const buttonWrapperClassNames = cn(['[&_a]:w-full', !isBtnDirectionCol && 'w-[12.9375rem]']);

  return (
    <Modal
      classNames={classNames}
      maxHeightClass={maxHeight}
      haveClose={haveCloseButton}
      onClose={onClose}
      show={show}>
      <div className="flex flex-col justify-between w-full h-full text-center mt-7 row">
        <div className="flex flex-col items-center">
          <Typography className="capitalize" variant="h6">
            {header}
          </Typography>
          {isMediumScreen ? (
            <>
              <Typography className="text-sm" variant="body-2">
                {description}
              </Typography>
              <Typography className="text-sm" variant="body-2">
                {descriptionNote}
              </Typography>
            </>
          ) : (
            <Typography className="text-sm" variant="body-2">
              {description}
              {descriptionNote}
            </Typography>
          )}
          {subText && (
            <Typography variant="body-1" className="max-w-md mt-8 sm:mt-4">
              {subText}
            </Typography>
          )}

          {children}
        </div>

        {errorMessage && <p className="mt-2 text-sm text-freight-200">{errorMessage}</p>}
        <div
          className={cn([
            'flex mt-1',
            !errorMessage && '!mt-10 sm:mt-6',
            isBtnDirectionCol ? 'flex-col' : 'flex-row justify-between space-x-4',
          ])}>
          {!hidePrimaryButton && (
            <Button
              shape={ButtonShape.CIRCLE}
              disabled={primaryBtnDisabled}
              buttonClassNames="w-full"
              classNames={buttonWrapperClassNames}
              Icon={primaryBtnIcon}
              label={primaryBtnLabel}
              onClick={primaryBtnOnClick}
              variant={primaryBtnVariant}
            />
          )}

          {!!secondaryBtnLabel && !!secondaryBtnOnClick && !hideSecondaryButton && (
            <Button
              disabled={secondaryBtnDisabled}
              buttonClassNames={cn([
                'w-full',
                isBtnDirectionCol && !hidePrimaryButton ? 'mt-4' : '',
              ])}
              classNames={buttonWrapperClassNames}
              Icon={secondaryBtnIcon}
              shape={ButtonShape.CIRCLE}
              label={secondaryBtnLabel}
              onClick={secondaryBtnOnClick}
              variant={secondaryBtnVariant}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
