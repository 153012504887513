import React from 'react';

import { isIndustrialOrPark } from '@/utilities/constants';
import { Typography } from '@components/Typography';
import { UnitSpecsProps } from './UnitSpecs.types';

const UnitSpecs: React.FC<UnitSpecsProps> = ({ attributes, propertyType, isMobileView }) => {
  return (
    <>
      <Typography
        id="unit-specs-heading"
        className="hidden mb-10 !text-2xl !leading-[30px] !font-medium md:block"
        variant="h5">
        Unit Details
      </Typography>
      <div
        id="unit-specs-value-section"
        className="flex-col w-full md:flex md:flex-row md:flex-wrap md:justify-between">
        {attributes?.map((unitSpec) =>
          isIndustrialOrPark(propertyType) &&
          unitSpec.label?.toLowerCase() === 'available size' ? null : (
            <div
              key={`unit-spec-${unitSpec.key}`}
              className={
                isMobileView
                  ? 'grid grid-cols-2'
                  : 'border-b border-cement-200 border-dotted grid grid-cols-2 mb-[0.9375rem] md:w-[45%]'
              }>
              <Typography
                id={`unit-spec-label`}
                variant="body-3"
                className="!font-normal !leading-5">
                {unitSpec.label}
                {isMobileView && ':'}
              </Typography>
              <Typography
                id={`unit-spec-value`}
                variant="body-3"
                className="!font-medium !leading-5">
                {unitSpec.value}
              </Typography>
            </div>
          ),
        )}
      </div>
    </>
  );
};

export default UnitSpecs;
