import { CheckboxState } from '@components/Inputs';
import { MultipleLevelCheckboxDropdownSelectorOption } from '@components/Inputs/MultipleLevelCheckBoxDropDown/MultipleLevelCheckboxDropdown.types';

export const getSelectedChildCount = (option: MultipleLevelCheckboxDropdownSelectorOption) => {
  const count = option?.children?.filter(
    (childOption) => childOption.checkedState === CheckboxState.CHECKED,
  ).length;
  return count ?? 0;
};

export const getParentStateBasedOnChilds = (
  children: MultipleLevelCheckboxDropdownSelectorOption[],
): CheckboxState => {
  const childsCount = children.length;
  let count = children?.filter(
    (childOption) => childOption.checkedState === CheckboxState.CHECKED,
  ).length;
  count = count ?? 0;
  if (count === 0) {
    return CheckboxState.EMPTY;
  } else if (count < childsCount) {
    return CheckboxState.INDETERMINATE;
  } else if (count === childsCount && CheckboxState.CHECKED) {
    return CheckboxState.CHECKED;
  } else {
    return CheckboxState.EMPTY;
  }
};
