import React, { useState } from 'react';
import cn from 'classnames';
import Button, { ButtonShape, ButtonVariant } from '@components/Button';
import { TextInput } from '@components/Inputs';
import Modal from '@components/Modal';
import { Typography } from '@components/Typography';
import { useToast } from '@/hooks/useToast';
import { useUserSavedSearches } from '@/hooks/useUserSavedSearches';
import useDelimitedActiveFiltersValues from '@/hooks/useDelimitedActiveFiltersValues';
import { EditSearchTitleModalProps } from './SavedSearchCard.types';
import { encodeString } from '@/utilities/textHelpers';

const EditSearchTitleModal: React.FC<EditSearchTitleModalProps> = ({
  savedSearch,
  setShowEditSearchTitleModal,
  value,
}) => {
  const emptySpacesError = 'Search name cannot be empty spaces or blank';
  const [input, setInput] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');

  const { addToast } = useToast();
  const { updateSavedSearch } = useUserSavedSearches();
  const startOfQueryParameterIndex = savedSearch.url.indexOf('?');
  const queryParameter = savedSearch.url.substring(startOfQueryParameterIndex);
  const { delimitedActiveFiltersValues } = useDelimitedActiveFiltersValues(queryParameter);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    errorMessage && setErrorMessage('');
    const encodedString = encodeString(e.target.value);
    setInput(encodedString);
  };

  const handleCloseEditSearchTitleModal = () => {
    setShowEditSearchTitleModal(false);
  };

  const handleEditSearchTitle = (updatedTitle: string) => {
    const { url, savedSearchId, emailAlertsEnabled } = savedSearch;
    if (input && input.trim().length <= 0) {
      setErrorMessage(emptySpacesError);
      return;
    }
    updateSavedSearch(
      savedSearchId,
      updatedTitle,
      url,
      emailAlertsEnabled,
      delimitedActiveFiltersValues,
    )
      .then(() => {
        addToast({
          description: 'Search Title Has Been Updated.',
          title: 'Success',
          type: 'success',
        });
        handleCloseEditSearchTitleModal();
      })
      .catch((error) => {
        const { exceptionMessage } = error.response.data.responseException;
        setErrorMessage(exceptionMessage);
      });
  };

  return (
    <Modal haveClose onClose={handleCloseEditSearchTitleModal} show>
      <div className="my-4">
        <Typography className="mb-6 text-center capitalize" variant="h6">
          Edit Search Title
        </Typography>
      </div>

      <Typography variant="body-4" className="mb-4">
        Title Name
      </Typography>

      <TextInput
        className={cn(['min-w-full mt-2 text-input', errorMessage ? 'mb-2' : 'mb-8'])}
        error={!!errorMessage}
        errorMessage={errorMessage}
        onChange={handleChange}
        value={input}
      />

      <Button
        buttonClassNames={errorMessage ? 'w-full mt-8' : 'w-full'}
        disabled={!input || !!errorMessage}
        label="Confirm"
        onClick={() => handleEditSearchTitle(input)}
        variant={ButtonVariant.PRIMARY_ONE}
        shape={ButtonShape.CIRCLE}
      />
    </Modal>
  );
};

export default EditSearchTitleModal;
