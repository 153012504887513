import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MAP_3D_ZOOM_THRESHOLD, MAP_FLY_ZOOM, MAP_STYLES } from '@/config';
import { AVAILABLE_SQ_FT, useAvailableSqFtButtonLabel } from '@/hooks/useAvailableSqFtButtonLabel';
import useMarkets from '@/hooks/useMarkets';
import { useGeolocation } from '@/pages/Map/hooks/useGeolocation';
import { FormattedLocation, InitialViewState } from '@/types';
import { LISTVIEW_CACHE_KEY } from '@/utilities/constants';
import AvailableSqFtFilterModal from '@components/AvailableSqFtFilterModal';
import Button, { ButtonVariant } from '@components/Button';
import {
  DatePicker,
  DatePickerMode,
  DatePickerSelectedDate,
  DatePickerTimeFrames,
  MinMaxRange,
} from '@components/Inputs';
import { timeFrameOptions } from '@components/Inputs/DatePicker/DatePicker.utils';
import SearchInput from '@components/Map/SearchInput/SearchInput';
import MapFilterModalButton from '@components/MapFilterModalButton';
import { Typography } from '@components/Typography';
import { useMapActions } from '../Map/hooks/useMapActions';
import { useMapData } from '../Map/hooks/useMapData';
import './LandingPage.css';
import { formatQueryParams } from './LandingPage.utils';
import { useCart } from '@/hooks/useReportCart';
import { useIsAuthenticated } from '@azure/msal-react';
import Footer from '@components/Footer';

const AVAILABLE_SQ_FT_DEFAULT = { min: '', max: '' };
const LandingPage: React.FC = () => {
  const { resetDatePicker, setSelectedOption, setDistanceOption } = useMapData();
  const { clearCart } = useCart();
  const { resetMapToggles, setMapStyle } = useMapActions();
  const [availableSqFtRange, setAvailableSqFtRange] =
    useState<MinMaxRange>(AVAILABLE_SQ_FT_DEFAULT);
  const [dateAvailable, setDateAvailable] = useState<Date | undefined>();
  const [dateTimeFrame, setDateTimeFrame] = useState<DatePickerTimeFrames | undefined>();
  const [marketId, setMarketId] = useState('');
  const [searchedLocation, setSearchedLocation] = useState<string>('');
  const [initialViewState, setInitialViewState] = useState<InitialViewState | null>(null);

  const availableSqFtButtonLabel = useAvailableSqFtButtonLabel(availableSqFtRange);
  const { nameLookup } = useMarkets();
  const navigate = useNavigate();
  const { updateCurrentLocation } = useGeolocation();
  const isAuth = useIsAuthenticated();

  const handleButtonClick = () => {
    const queryParams = formatQueryParams(
      availableSqFtRange,
      dateAvailable,
      dateTimeFrame,
      marketId,
      searchedLocation,
      initialViewState,
    );
    const mapRoute = `/map${queryParams}`;

    navigate(mapRoute);
  };

  const handleDateAvailableSelect = (selection: DatePickerSelectedDate, mode: DatePickerMode) => {
    if (selection) {
      if (mode === DatePickerMode.TIMEFRAME) {
        if (selection === '180') {
          let currentDate = new Date();
          let nextSixMonthDate = new Date();
          nextSixMonthDate.setMonth(nextSixMonthDate.getMonth() + 6);

          const totalDays = Math.round(
            (nextSixMonthDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
          );

          setDateTimeFrame(totalDays.toString() as DatePickerTimeFrames);
        } else {
          setDateTimeFrame(selection as DatePickerTimeFrames);
        }
        setDateAvailable(undefined);
      } else {
        setDateAvailable(selection as Date);
        setDateTimeFrame(undefined);
      }
    } else {
      setDateAvailable(undefined);
      setDateTimeFrame(undefined);
    }
  };

  const handleLocationClear = () => {
    setMarketId('');
    setSearchedLocation('');
    setInitialViewState(null);
  };

  const handleLocationSelect = (selectedOption: FormattedLocation | null) => {
    if (!selectedOption) {
      handleLocationClear();
      return;
    }

    setSearchedLocation(selectedOption.displayName);

    if (selectedOption.category === 'markets') {
      const marketId = nameLookup.get(selectedOption.displayName)!;
      setMarketId(marketId);
    } else {
      const lngLatBoundsLike = (selectedOption.boundingBox ?? selectedOption.center).flat();
      let zoom: number | undefined;
      let selectedOptionType = null;
      switch (selectedOption.category) {
        case 'locations': {
          zoom = MAP_FLY_ZOOM;
          selectedOptionType = selectedOption.category;
          setSelectedOption(selectedOption);
          setDistanceOption(selectedOption);
          break;
        }

        case 'listingNames':
        case 'propertyAddresses': {
          selectedOptionType = selectedOption.category;
          zoom = MAP_3D_ZOOM_THRESHOLD;
          setSelectedOption(selectedOption);
          setDistanceOption(selectedOption);
          break;
        }
      }

      const viewState: InitialViewState = {
        lngLatBoundsLike,
        zoom,
        listingId: selectedOption.listingId ?? undefined,
        selectedOptionCategory: selectedOptionType,
      };

      setInitialViewState(viewState);
    }
  };

  // Reset to map view everytime a user comes to landing page
  sessionStorage.setItem(LISTVIEW_CACHE_KEY, 'map');
  useEffect(() => {
    resetDatePicker?.();
    resetMapToggles?.();
    setMapStyle?.(MAP_STYLES[0].url);
    setDistanceOption(null);
    clearCart();
  }, []);

  timeFrameOptions[0].label = 'Available Now';

  return (
    <>
      <div className="landing-page">
        <div className="landing-page-gutter">
          <Typography
            className="lg:!text-[2.8rem] font-semibold landing-page-heading text-white-100"
            variant="h3">
            Welcome to Property Search
          </Typography>

          <div className="landing-search-container">
            <div className="landing-search-inputs">
              <SearchInput
                classNames="landing-search-item landing-search-input"
                showMyLocation
                dropdownHeight={16}
                onMyLocation={async () => {
                  const currentLocation = await updateCurrentLocation();
                  if (!currentLocation) {
                    return;
                  }
                  const { longitude, latitude } = currentLocation;
                  setDistanceOption({
                    optionIndex: -1,
                    category: 'locations',
                    center: [longitude, latitude],
                    boundingBox: null,
                    displayName: 'Current Location',
                    listingId: null,
                    types: ['currentLocation'],
                  });
                  setSearchedLocation('Current Location');
                  const viewState: InitialViewState = {
                    lngLatBoundsLike: [longitude, latitude],
                    zoom: 8,
                    listingId: undefined,
                  };
                  setInitialViewState(viewState);
                }}
                customClearInput={handleLocationClear}
                customOnSelect={handleLocationSelect}
                selection={searchedLocation}
              />
              <div className="flex landing-date-filter md:flex md:flex-row">
                {isAuth && (
                  <DatePicker
                    classNames="landing-search-item landing-date-input"
                    disablePastDays
                    onSelect={handleDateAvailableSelect}
                    placeholder="Date Available"
                    selectedDate={dateAvailable}
                    selectedTimeFrame={dateTimeFrame}
                    timeFrameOptions={timeFrameOptions}
                  />
                )}
                <MapFilterModalButton
                  classNames="landing-search-item landing-filter-modal"
                  buttonLabel={availableSqFtButtonLabel}
                  disableMousedownEventListener
                  Modal={AvailableSqFtFilterModal}
                  onRemoveInputValueClearingPill={() =>
                    setAvailableSqFtRange(AVAILABLE_SQ_FT_DEFAULT)
                  }
                  setSqFtRange={setAvailableSqFtRange}
                  showInputValueClearingPill={availableSqFtButtonLabel !== AVAILABLE_SQ_FT}
                  sqFtRange={availableSqFtRange}
                  maxWidth="max-w-[7.8125rem]"
                />
              </div>
            </div>
            <Button
              buttonClassNames="landing-search-btn border-none"
              classNames="sm:pt-4 lg:pt-0"
              onClick={handleButtonClick}
              label="Search"
              variant={ButtonVariant.PRIMARY_TWO}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LandingPage;
