import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';

export interface ListingPageMetaTagProps {
  name?: string;
  address?: string;
  size?: string;
  location?: string;
  seoJsonMarkup?: string;
}
export const ListingPageMetaTag: React.FC<ListingPageMetaTagProps> = ({
  name,
  address,
  size,
  location,
  seoJsonMarkup,
}) => {
  const getTitle = () => {
    if (cleanName) {
      return `${cleanName} | Industrial Property | Link Logistics`;
    } else {
      return 'Industrial Property | Link Logistics';
    }
  };

  const getDescription = () => {
    if (cleanName && address) {
      return `${cleanName} offers ${size} square footage of industrial real estate space available for lease in ${location}. Located at ${address}.`;
    } else if (cleanName && !address) {
      return `${cleanName} offers ${size} square footage of industrial real estate space available for lease in ${location}.`;
    } else if (!cleanName && address) {
      return `This property offers ${size} square footage of industrial real estate space available for lease in ${location}. Location at ${address}`;
    } else {
      return `This property offers ${size} square footage of industrial real estate space available for lease in ${location}.`;
    }
  };

  const getCleanName = (name: string) => {
    if (name?.includes(' - Unit --')) {
      const index = name.lastIndexOf(' - Unit --');
      const nameOnly = name.substring(0, index);
      return nameOnly;
    }
    return name;
  };

  const [cleanName, setCleanName] = useState(getCleanName(name!));
  const [title, setTitle] = useState(getTitle());
  const [description, setDescription] = useState(getDescription());

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
    setCleanName(getCleanName(name!));
  }, [cleanName, name, address, location, size, seoJsonMarkup]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:site_name" content={'Link Logistics Property Search'} />
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <script type="application/ld+json">{seoJsonMarkup}</script>
    </Helmet>
  );
};

export default ListingPageMetaTag;
