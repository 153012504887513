import React, { useState } from 'react';
import Modal from '@components/Modal';
import { RequestInformationModalProps } from './RequestInformationModal.types';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import { BROKER_CONTACT_FORM_IFRAME_LINK } from '@/config';

const RequestInformationModal: React.FC<RequestInformationModalProps> = ({
  modalData,
  onClose,
}) => {
  const [loading, setLoading] = useState(true);

  const queryString = `PID=${encodeURI(modalData?.propertyId!)}&PS_UnitID=${encodeURI(
    modalData?.unitName ?? '',
  )}&PS_Listing_UUID=${encodeURI(modalData?.listingId!)}&PS_Broker_Name=${encodeURI(
    modalData?.brokerName!,
  )}&PS_Broker_Email=${encodeURI(modalData?.brokerEmail!)}&PS_Broker_Phone=${encodeURI(
    modalData?.brokerPhone!,
  )}&PS_Broker_Shop=${encodeURI(modalData?.brokerShop!)}&PS_Link_Rep_Email=${encodeURI(
    modalData?.linkRepEmail!,
  )}&PS_Link_Rep_Name=${encodeURI(modalData?.linkRepName!)}&PS_Link_Rep_Phone=${encodeURI(
    modalData?.linkRepPhone!,
  )}`;
  return (
    <Modal
      haveClose
      onClose={onClose}
      show
      maxHeightClass="!h-full !w-full !max-w-none !mt-0 !mb-0"
      classNames={'!h-full !w-full opacity-90 !bg-solar-100 !py-4 !px-0'}>
      <div className="frame">
        <div className="form-container flex flex-col items-center justify-center w-[66%]">
          <header className="flex items-center justify-between w-full">
            <h1 className="heading-text-wrapper">Request Information</h1>
          </header>

          <div className="listing-details">
            <h2 className="name-text-wrapper">{modalData?.listingName}</h2>
            <p className="address-text-wrapper">{modalData?.listingAddress}</p>
          </div>
        </div>
      </div>
      {loading && (
        <div className="iframe-loader">
          <LoadingSpinner />
        </div>
      )}
      <div className="iframe-container">
        <iframe
          src={`${BROKER_CONTACT_FORM_IFRAME_LINK}?${queryString}`}
          width="100%"
          height="100%"
          id="pardot-form-iframe"
          title="pardot-contact-form"
          onLoad={() => setLoading(false)}></iframe>
      </div>
    </Modal>
  );
};

export default RequestInformationModal;
