export const ternaryOperation = (condition: any, ifTrue: any, ifFalse: any) => {
  return condition ? ifTrue : ifFalse;
};

export const sortBy = (valuesToSort: any[] | undefined, sortOrder: string | any[]) => {
  if (!Array.isArray(valuesToSort) || !Array.isArray(sortOrder)) {
    throw new Error('Both valuesToSort and sortOrder should be arrays');
  }

  const ordering = new Map<string, number>(sortOrder.map((key, index) => [key, index]));

  return valuesToSort.slice().sort((a, b) => {
    const indexA = ordering.has(a.key) ? ordering.get(a.key)! : sortOrder.length;
    const indexB = ordering.has(b.key) ? ordering.get(b.key)! : sortOrder.length;

    return indexA - indexB || a.key.localeCompare(b.key);
  });
};

export const renameKeysDeep = (obj: any, keyMap: { [x: string]: any }): any[] => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => renameKeysDeep(item, keyMap));
  }

  const renamedObj: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = keyMap[key] || key;
      renamedObj[newKey] = renameKeysDeep(obj[key], keyMap);
    }
  }
  return renamedObj;
};
