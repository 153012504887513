import cn from 'classnames';
import React, { useId, useLayoutEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import './ConstrainedTypography.css';
import { Typography, TypographyProps } from '@components/Typography';

interface ConstrainedTypographyProps extends TypographyProps {
  width?: string;
}

export const ConstrainedTypography: React.FC<ConstrainedTypographyProps> = ({
  width,
  children,
  className,
  ...props
}: ConstrainedTypographyProps) => {
  const tooltipId = useId();
  const ref = useRef<HTMLElement | null>(null);

  const [showTooltip, setShowTooltip] = useState(false);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientWidth <= ref.current.scrollWidth) {
      setShowTooltip(true);
    }
  }, [ref]);

  return (
    <>
      <div data-tooltip-id={tooltipId} data-tooltip-offset={3}>
        <Typography className={cn(['truncate', width, className])} ref={ref} {...props}>
          {children}
        </Typography>
      </div>

      {showTooltip && (
        <Tooltip id={tooltipId} className="!w-fit z-10">
          <Typography variant="body-4">{children}</Typography>
        </Tooltip>
      )}
    </>
  );
};
