import React, { useMemo } from 'react';
import { Popup, PopupProps } from 'react-map-gl';

import { useApiClient } from '@/hooks/useApiClient';
import { TemplateTypes } from '@/types';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import MapPinPopupContent from './MapPinPopupContent';

import { useMapData } from '@/pages/Map/hooks/useMapData';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import './MapPinPopup.css';

export interface MapPinPopupProps {
  latitude: number;
  listingId: string;
  longitude: number;
  popupProps?: Partial<PopupProps>;
}

export const MapPinPopup: React.FC<MapPinPopupProps> = ({
  latitude,
  listingId,
  longitude,
  popupProps,
}) => {
  const { getListingData } = useApiClient();
  const { setSelectedListing } = useMapData();
  const isSmallAndMediumScreen = useMediaQuery({ query: '(max-width: 1023px)' });
  const { data, isLoading } = getListingData(TemplateTypes.ListingPopUp, [listingId]);
  const navigate = useNavigate();

  const handleResultSelect = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (isSmallAndMediumScreen) {
      // Clear the selected listing
      setSelectedListing(null);
      navigate(`/listing/${listingId}`);
    }
  };

  const popupData = useMemo(() => {
    if (!data) {
      return null;
    }

    const listingData = data[0];

    const thumbnailImageUrl = listingData?.attributes.find(
      (x) => x.key === 'THUMBNAIL_IMAGE_URL',
    )?.value;
    const name = listingData?.attributes.find((x) => x.key === 'NAME')?.value;
    const fullAddress = listingData?.attributes.find((x) => x.key === 'FULL_ADDRESS')?.value;
    const size = listingData?.attributes.find((x) => x.key === 'SIZE')?.value;
    const unitName = listingData?.attributes.find((x) => x.key === 'UNIT_NAME')?.rawValue;

    return {
      thumbnailImageUrl,
      name,
      fullAddress,
      size,
      unitName,
    };
  }, [data]);

  return (
    <Popup
      className="overflow-hidden font-inter rounded-lg map-pin-popup drop-shadow-md shadow-base-black w-[18rem] pb-10"
      closeButton={false}
      latitude={latitude}
      longitude={longitude}
      anchor="top"
      maxWidth="none"
      offset={{
        top: [0, 35],
        left: [35, 25],
        right: [-35, 25],
        'top-left': [25, 25],
        'top-right': [-25, 25],
        'bottom-left': [25, 5],
        'bottom-right': [-25, 5],
      }}
      {...popupProps}>
      {isLoading ? (
        <div className="flex justify-center items-center w-[15.625rem] h-[15.625rem] p-4">
          <LoadingSpinner />
        </div>
      ) : (
        // onMouseEnter is used for iOS because first click behaves as Hover. But for all other small devices, click event will be used.
        <div onMouseEnter={(e) => handleResultSelect(e)} onClick={(e) => handleResultSelect(e)}>
          <MapPinPopupContent
            thumbnailUrl={popupData?.thumbnailImageUrl}
            name={popupData?.name}
            fullAddress={popupData?.fullAddress}
            size={popupData?.size}
            unitName={popupData?.unitName ?? undefined}
          />
        </div>
      )}
    </Popup>
  );
};

export default MapPinPopup;
