import { useApiClient } from '@/hooks/useApiClient';
import { Typography } from '@components/Typography';
import { decode } from 'html-entities';
import React from 'react';

type MarketingBannerProps = { isBigScreen: boolean };

const MarketingBanner: React.FC<MarketingBannerProps> = ({ isBigScreen }) => {
  const { getAppConfigByKey } = useApiClient();
  const marketBannerText = getAppConfigByKey('MarketBannerText');
  const decodedText = decode(marketBannerText?.data?.value);

  return (
    <div
      id="Marketing-Banner"
      className="flex items-center justify-center md:[h-60px] h-[76px]  bg-solar-600 px-6 py-2 md:p-2.5">
      <Typography
        component="span"
        variant={isBigScreen ? 'body-3' : 'body-4'}
        className="!font-normal">
        {decodedText}
      </Typography>
    </div>
  );
};

export default MarketingBanner;
