import cn from 'classnames';
import React, { useState } from 'react';

import { ValidateEmail } from '@/utilities/textHelpers';
import Button, { ButtonShape, ButtonVariant } from '@components/Button';
import { Icon } from '@components/Icon';
import { Checkbox, CheckboxState, TextInput } from '@components/Inputs';
import Modal from '@components/Modal';
import { Typography } from '@components/Typography';
import { BtnsDirection, ShareModalProps } from './ShareModal.types';
import { Tooltip } from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import { ReactMultiEmail } from 'react-multi-email';

const ShareModal: React.FC<ShareModalProps> = ({
  cssClassNames,
  header,
  mainActionIcon,
  mainActionLabel,
  onMainAction,
  onSendEmail,
  onClose,
  show,
  attachReportCheckBox,
  singleRecipient,
}) => {
  const isBtnDirectionCol = BtnsDirection.COL;
  const [emailFieldIsActive, setEmailFieldIsActive] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });
  const isMediumScreen = useMediaQuery({ query: '(min-width: 768px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const [emails, setEmails] = useState<string[]>([]);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [attachReport, setAttachReport] = useState<CheckboxState>(CheckboxState.EMPTY);

  const buttonWrapperClassNames = cn(['[&_a]:w-full', !isBtnDirectionCol && 'w-[12.9375rem]']);

  const onChangeEmails = (emails: string[]) => {
    setEmails(emails);
  };

  const getLabelInput = (email: string, index: number, removeEmail: (index: number) => void) => (
    <div data-tag key={index}>
      {email}
      <button
        onClick={() => removeEmail(index)}
        style={{ cursor: 'pointer', marginLeft: '6px', fontSize: 18 }}>
        ×
      </button>
    </div>
  );

  return (
    <Modal
      classNames={cssClassNames}
      haveClose
      onClose={() => {
        setEmails([]);
        setAttachReport(CheckboxState.EMPTY);
        onClose();
      }}
      show={show}>
      <div className="flex flex-col justify-between w-full h-full text-center mt-7 row">
        <div>
          <Typography className="capitalize" variant="h6">
            {header}
          </Typography>
          <br />
          <Button
            shape={ButtonShape.CIRCLE}
            buttonClassNames="w-full"
            classNames={buttonWrapperClassNames}
            Icon={<Icon name={mainActionIcon} />}
            label={mainActionLabel}
            variant={ButtonVariant.DEFAULT_OUTLINE}
            data-testid="main-action"
            onClick={() => {
              onMainAction();
              onClose();
            }}
          />
          <div className="relative flex items-center py-5">
            <div className="flex-grow border-t border-cement-200"></div>
            <span className="flex-shrink mx-4 text-sm text-cement-200">or</span>
            <div className="flex-grow border-t border-cement-200"></div>
          </div>
        </div>

        <div
          className={cn([
            'flex text-left',
            isBtnDirectionCol ? 'flex-col' : 'flex-row justify-between',
          ])}>
          <div className="text-left">
            {!singleRecipient ? (
              <>
                <Typography variant="body-4" className="mb-4">
                  Recipients&apos; Emails
                </Typography>
                {isLargeScreen && emails.length > 0 && (
                  <Tooltip id="emails-info" className="z-10">
                    <Typography
                      variant="body-4"
                      className="sm:max-w-[16rem] md:max-w-[22rem] sm:max-h-[15rem] md:max-h-[25rem]">
                      {emails.join(', ')}
                    </Typography>
                  </Tooltip>
                )}
                <div
                  className="mb-4 multiple-email"
                  data-tooltip-id="emails-info"
                  data-tooltip-place="top">
                  <ReactMultiEmail
                    onFocus={() => setEmailFieldIsActive(true)}
                    onBlur={() => setEmailFieldIsActive(false)}
                    allowDuplicate={false}
                    emails={emails}
                    style={{
                      maxWidth: isMediumScreen ? '445px' : '100%',
                      maxHeight: isSmallScreen ? 110 : 200,
                    }}
                    className={` ${
                      emailFieldIsActive
                        ? '!bg-base-white !border-solid !border-black'
                        : '!bg-cement-100 !border-none'
                    } !mb-[0.125rem] !w-full !text-sm !h-12 !rounded-[26px]  !p-0 !overflow-y-auto   focus:!border-solid focus:!border-black `}
                    onChange={onChangeEmails}
                    getLabel={getLabelInput}
                  />
                </div>
              </>
            ) : (
              <>
                <Typography variant="body-4" className="mb-4">
                  Email
                </Typography>
                <TextInput
                  classNames="w-full h-[5.375rem]"
                  name="email-input"
                  onChange={(e) => {
                    setEmails((prev) => [e.target.value]);
                    setEmailError(ValidateEmail(e.target.value));
                  }}
                  value={emails[0]}
                  error={emailError}
                  errorMessage={'Email address is not valid.'}
                />
              </>
            )}
          </div>
          {attachReportCheckBox && (
            <div data-tooltip-id="max-listings-tooltip" data-tooltip-target="tooltip-default">
              <Checkbox
                className="mb-4"
                checkedState={attachReport}
                disableIndeterminateOption
                disabled={attachReportCheckBox.disabled}
                label={attachReportCheckBox.label}
                onClick={(checkedState) => {
                  setAttachReport(checkedState);
                }}
              />
            </div>
          )}

          <Button
            shape={ButtonShape.CIRCLE}
            buttonClassNames="w-full"
            classNames={buttonWrapperClassNames}
            label="Send Via Email"
            disabled={!emails || emails.length === 0}
            variant={ButtonVariant.PRIMARY_ONE}
            onClick={() => {
              onSendEmail(emails, attachReport === CheckboxState.CHECKED);
              onClose();
              setEmails([]);
              setAttachReport(CheckboxState.EMPTY);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
