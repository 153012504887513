import useDynamicMediaQuery from '@/hooks/useDynamicMediaQuery';
import { Icon, IconName } from '@components/Icon';
import Modal from '@components/Modal';
import { Typography } from '@components/Typography';
import cn from 'classnames';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import InputValueClearingPill from '../InputValueClearingPill';
import RadioButton from '../RadioButton/RadioButton';
import './DatePicker.css';
import { DatePickerMode, DatePickerProps, DatePickerSelectedDate } from './DatePicker.types';
import {
  formatWeekdayName,
  getFromYear,
  getLabelFromTimeFrameValue,
  getToYear,
  timeFrameOptions,
} from './DatePicker.utils';

const DatePicker: React.FC<DatePickerProps> = ({
  classNames,
  disabled,
  disablePastDays,
  error,
  errorMessage,
  footer,
  onSelect,
  placeholder = 'Select Date',
  selectedDate,
  selectedTimeFrame,
  showList = false,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(showList);
  const [mode, setMode] = useState<DatePickerMode>(DatePickerMode.TIMEFRAME);

  const datePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!selectedDate && !selectedTimeFrame) setInputValue(placeholder);
    if (selectedTimeFrame) {
      const newInputValue = getLabelFromTimeFrameValue(selectedTimeFrame);
      setInputValue(newInputValue);
      setMode(DatePickerMode.TIMEFRAME);
    } else if (selectedDate) {
      setInputValue(format(selectedDate, 'MM/dd/yy'));
      setMode(DatePickerMode.DATE);
    }
  }, [selectedDate, selectedTimeFrame]);

  const handleModifyingDay = () => {
    if (disablePastDays) {
      return {
        disabled: { before: new Date() },
      };
    }
  };

  const handleSelect = (selection: DatePickerSelectedDate) => {
    onSelect && onSelect(selection, mode);

    setIsOpen(false);
  };

  const handleModeChange = (mode: DatePickerMode) => {
    setInputValue(placeholder);
    onSelect && onSelect(undefined, mode);
    setMode(mode);
  };

  const inputClassNames = cn([
    'datepicker-wrapper text-input !rounded-full',
    error && 'text-input--error',
    disabled && 'datepicker-container--disabled',
    isOpen && 'rounded-b-none',
  ]);

  const buttonClassNames =
    'border-base-white border flex font-medium grow items-center justify-center p-2 text-xs tracking-wider uppercase';

  const leftButtonClassNames = cn([
    buttonClassNames,
    'rounded-l',
    mode === DatePickerMode.TIMEFRAME
      ? 'bg-base-white text-slate-500'
      : 'bg-transparent text-base-white',
  ]);

  const rightButtonClassNames = cn([
    buttonClassNames,
    'rounded-r',
    mode === DatePickerMode.DATE
      ? 'bg-base-white text-slate-500'
      : 'bg-transparent text-base-white',
  ]);

  return (
    <div className={cn(['relative flex flex-col justify-start', classNames])} ref={datePickerRef}>
      <div className={inputClassNames} onClick={!disabled ? () => setIsOpen(!isOpen) : undefined}>
        <Icon classNames="!h-6 !w-6 pointer-event-none" name={IconName.CALENDAR} />
        {inputValue === 'Date Available' && <Typography variant="body-3">{inputValue}</Typography>}
        {inputValue && inputValue !== placeholder && inputValue !== 'Date Available' && (
          <InputValueClearingPill
            classNames="left-[2.875rem] [&_svg]:!mr-0"
            onRemove={() => onSelect && onSelect(undefined, mode)}
            value={inputValue}
          />
        )}
      </div>

      {useDynamicMediaQuery('(max-width: 499px)')
        ? isOpen && (
            <div className="datepicker-modal">
              <Modal
                onClose={() => {
                  setIsOpen(false);
                }}
                haveClose
                theme="white"
                show>
                <Typography className="text-center datepicker-modal-title" variant="h6">
                  Select a Date
                </Typography>
                <div className="datepicker-list-wrapper">
                  <div className={'dropdown-list datepicker-dropdown !border-none'}>
                    <div className="flex w-full px-4 py-6">
                      <button
                        className={leftButtonClassNames}
                        onClick={() => handleModeChange(DatePickerMode.TIMEFRAME)}>
                        <Icon classNames="mr-1" name={IconName.CLOCK} />
                        Time Frame
                      </button>
                      <button
                        className={rightButtonClassNames}
                        onClick={() => handleModeChange(DatePickerMode.DATE)}>
                        <Icon classNames="mr-1" name={IconName.CALENDAR} />
                        Calendar
                      </button>
                    </div>
                    {mode === DatePickerMode.DATE ? (
                      <DayPicker
                        captionLayout="dropdown"
                        defaultMonth={selectedDate}
                        footer={footer}
                        formatters={{ formatWeekdayName }}
                        fromYear={getFromYear()}
                        modifiers={handleModifyingDay()}
                        mode={'single'}
                        onSelect={handleSelect}
                        selected={selectedDate}
                        showOutsideDays
                        toYear={getToYear()}
                        weekStartsOn={1}
                      />
                    ) : (
                      <div className="h-[19rem] px-4">
                        <Typography variant="subtitle-2">Availability Time Frame</Typography>
                        <div className="flex flex-col space-y-5 pt-5">
                          {timeFrameOptions.map((timeframe) => (
                            <RadioButton
                              checked={selectedTimeFrame === timeframe.value}
                              key={timeframe.value}
                              label={timeframe.label}
                              onClick={(timeFrame) => handleSelect(timeFrame)}
                              value={timeframe.value}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Modal>
            </div>
          )
        : isOpen && (
            <div className={'dropdown-list datepicker-dropdown !rounded-[20px]'}>
              <div className="flex w-full px-4 py-6">
                <button
                  className={leftButtonClassNames}
                  onClick={() => handleModeChange(DatePickerMode.TIMEFRAME)}>
                  <Icon classNames="mr-1" name={IconName.CLOCK} />
                  Time Frame
                </button>
                <button
                  className={rightButtonClassNames}
                  onClick={() => handleModeChange(DatePickerMode.DATE)}>
                  <Icon classNames="mr-1" name={IconName.CALENDAR} />
                  Calendar
                </button>
              </div>
              {mode === DatePickerMode.DATE ? (
                <DayPicker
                  captionLayout="dropdown"
                  defaultMonth={selectedDate}
                  footer={footer}
                  formatters={{ formatWeekdayName }}
                  fromYear={getFromYear()}
                  modifiers={handleModifyingDay()}
                  mode={'single'}
                  onSelect={handleSelect}
                  selected={selectedDate}
                  showOutsideDays
                  toYear={getToYear()}
                  weekStartsOn={1}
                />
              ) : (
                <div className="h-[19rem] px-4">
                  <Typography variant="subtitle-2">Availability Time Frame</Typography>
                  <div className="flex flex-col space-y-5 pt-5">
                    {timeFrameOptions.map((timeframe) => (
                      <RadioButton
                        checked={selectedTimeFrame === timeframe.value}
                        key={timeframe.value}
                        label={timeframe.label}
                        onClick={(timeFrame) => handleSelect(timeFrame)}
                        value={timeframe.value}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

      {errorMessage && <p className="mt-1.5 text-freight-100">{errorMessage}</p>}
    </div>
  );
};

export default DatePicker;
