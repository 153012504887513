import React, { useState } from 'react';
import Button, { ButtonShape, ButtonVariant } from '@components/Button';
import {
  DropdownSelector,
  DropdownSelectorColor,
  DropdownSelectorOption,
  DropdownSelectorSelectedOption as SelectedOption,
} from '@components/Inputs';
import { Typography } from '@components/Typography';
import Modal from '@components/Modal';
import { useToast } from '@/hooks/useToast';
import { ContactUsModalProps } from './ContactUsModal.types';
import { encodeString } from '@/utilities/textHelpers';
import { useApiClient } from '@/hooks/useApiClient';
import TextArea from '@components/Inputs/TextArea/TextArea';
import { MAX_CHARACTERS_ALLOWED_FOR_CONTACT_MESSAGE_BOX } from '@/utilities/constants';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import cn from 'classnames';

const ContactUsModal: React.FC<ContactUsModalProps> = ({ onClose }) => {
  const contactUsOptions = [
    { label: 'Idea', value: 'Idea' },
    { label: 'Issue', value: 'Issue' },
    { label: 'Question', value: 'Question' },
    { label: 'Other', value: 'Other' },
  ];

  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [submissionType, setSubmissionType] = useState('');
  const [maxCharactersRemaining, setMaxCharactersRemaining] = useState(
    MAX_CHARACTERS_ALLOWED_FOR_CONTACT_MESSAGE_BOX,
  );
  const [scroll, setScroll] = useState(false);

  const { submitAnIdea } = useApiClient();
  const { mutate: submitAnIdeaMutation } = submitAnIdea();
  const { addToast } = useToast();
  const isBigScreen = useIsBigScreen();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>, submissionType: string) => {
    setMaxCharactersRemaining(
      MAX_CHARACTERS_ALLOWED_FOR_CONTACT_MESSAGE_BOX - e.target.value.length,
    );
    if (e.target.value.length == e.target.selectionEnd) {
      e.target.style.height = '';
      e.target.style.height = e.target.scrollHeight + 'px';
      if (e.target.scrollHeight > 208) {
        // Enable scrollbar once max height it reached
        setScroll(true);
      } else {
        setScroll(false);
      }
    }
    errorMessage && setErrorMessage('');
    const encodedString = encodeString(e.target.value);
    setSubmissionType(submissionType);
    setMessage(encodedString);
  };
  const handleSubmitIdea = () => {
    try {
      submitAnIdeaMutation({ submissionType: submissionType, message: message });
      addToast({
        id: `submit-idea-${submissionType}-${message}`,
        description: 'Submission Received',
        title: 'Success',
        type: 'success',
      });
      onClose();
    } catch (error: any) {
      const { exceptionMessage } = error.response.data.responseException;
      setErrorMessage(exceptionMessage);
    }
  };

  const modalContent = (submissionType: string) => {
    switch (submissionType) {
      case 'Idea':
        return (
          <div>
            <Typography className="mb-2 bo" variant="subtitle-3">
              Helpful Information to Include:
            </Typography>
            <Typography className="mb-5 ml-2 bo" variant="body-3">
              <ul>
                <li>&bull; the value this could provide</li>
                <li>&bull; the problem this could solve</li>
              </ul>
            </Typography>
            <Typography className="mb-1" variant="body-4">
              What&apos;s your idea?
            </Typography>
          </div>
        );
      case 'Issue':
        return (
          <div>
            <Typography className="mb-2 bo" variant="subtitle-3">
              Helpful Information to Include:
            </Typography>
            <Typography className="mb-5 ml-2 bo" variant="body-3">
              <ul>
                <li>&bull; the value this could provide</li>
                <li>&bull; the problem this could solve</li>
                <li>&bull; steps to reproduce</li>
              </ul>
            </Typography>
            <Typography className="mb-1" variant="body-4">
              Describe the issue
            </Typography>
          </div>
        );
      case 'Question':
        return (
          <div>
            <Typography className="mb-1" variant="body-4">
              Ask us anything
            </Typography>
          </div>
        );
      case 'Other':
        return (
          <div>
            <Typography className="mb-1" variant="body-4">
              Message
            </Typography>
          </div>
        );
    }
  };
  return (
    <Modal
      haveClose
      onClose={onClose}
      show
      classNames={isBigScreen ? '' : 'min-h-[22.5rem] max-h-[calc(100vh-3.75rem)] pt-6'}>
      <Typography className="mb-6 text-center mt-7" variant="h6">
        Contact Us
      </Typography>

      <Typography className="mb-1 bo" variant="body-4">
        What do you want to submit?
      </Typography>

      <DropdownSelector
        classNames="mb-7 justify-between"
        color={DropdownSelectorColor.GRAY}
        options={contactUsOptions}
        onSelect={(selectedOption) => {
          const option = selectedOption as DropdownSelectorOption;
          setSubmissionType(option.label);
        }}
        selectedOption={
          submissionType ? { label: submissionType, value: submissionType } : undefined
        }
      />
      <div>
        {modalContent(submissionType)}
        {submissionType && (
          <TextArea
            className={cn([
              'text-area min-w-full sm:!max-h-[13rem] md:!max-h-[100%]',
              scroll ? 'sm:!overflow-y-auto md:!overflow-hidden' : '',
            ])}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onChange={(e) => handleChange(e, submissionType)}
            maxCharactersRemaining={maxCharactersRemaining}
            showRemainingCharactersText={true}
            maxLength={MAX_CHARACTERS_ALLOWED_FOR_CONTACT_MESSAGE_BOX}
          />
        )}
      </div>
      <Button
        buttonClassNames={cn([isBigScreen ? 'w-full bg-slate-500' : 'w-full bg-slate-500 mt-8'])}
        disabled={!submissionType || !message || maxCharactersRemaining < 0}
        label="Submit"
        onClick={handleSubmitIdea}
        variant={ButtonVariant.PRIMARY_ONE}
        shape={ButtonShape.CIRCLE}
      />
    </Modal>
  );
};

export default ContactUsModal;
