import { useApiClient } from '@/hooks/useApiClient';
import { encodeSubMarket } from '@/pages/ListingDetails/ListingDetails.utils';
import { Market } from '@/types';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export function MarketRouteFilter() {
  const navigate = useNavigate();
  const params = useParams();
  const { getMarketByName } = useApiClient();
  const marketName = encodeSubMarket(params.market as string);
  const queryString = location.search;
  const [market, setMarket] = useState<Market | undefined>();

  const { data: mData, isLoading, isFetching, isError } = getMarketByName(marketName!);

  useEffect(() => {
    if (!marketName) {
      navigate('/map', { replace: true });
      return;
    }
    setMarket(mData);
  }, [mData, isFetching, isLoading]);

  useEffect(() => {
    if (isError) {
      navigate('/map', { replace: true });
      return;
    }
    if (!isLoading && !isFetching) {
      var url = '/map';
      if (market) {
        if (queryString) {
          url = `/map${queryString}&market_id=${market.marketId}`;
        } else {
          url = `/map?market_id=${market.marketId}`;
        }
      }
      navigate(url, { replace: true });
      return;
    }
  }, [market, isError]);

  return (
    <div className="flex items-center justify-center w-full p-10">
      <LoadingSpinner />
    </div>
  );
}
