import {
  BTSType,
  DevelopmentType,
  ExternalNewClassType,
  IndustrialType,
  InternalNewClassType,
  ParksType,
  UCType,
} from '@/utilities/constants';
import { CheckboxDropdownSelectorOption } from '@components/Inputs/CheckboxDropdown/CheckboxDropdown.types';

export const originalOptions: CheckboxDropdownSelectorOption[] = [
  {
    label: IndustrialType.internalLabel,
    value: IndustrialType.value,
  },
  {
    label: ParksType.internalLabel,
    value: ParksType.internalLabel,
  },
  {
    label: DevelopmentType.internalLabel,
    value: DevelopmentType.value,
    children: [
      {
        label: InternalNewClassType.label,
        value: InternalNewClassType.label,
      },
      {
        label: UCType.label,
        value: UCType.label,
      },
      {
        label: BTSType.label,
        value: BTSType.label,
      },
    ],
  },
];

export const originalOptionsUnauthenticated: CheckboxDropdownSelectorOption[] = [
  {
    label: IndustrialType.externalLabel,
    value: IndustrialType.value,
  },
  {
    label: ExternalNewClassType.label,
    value: ExternalNewClassType.value,
  },
  {
    label: BTSType.label,
    value: BTSType.label,
  },
];
