import { TemplateValue } from '@/types/templates';
import React, { Fragment } from 'react';
import ListingInfoElementsWrapper, { AttributeValueType } from './ListingDetails.utils';

export interface ListingDetailsContentDesktopProps {
  data: TemplateValue[];
  isRouteHistoryStackEmpty: boolean;
  isSaved: boolean;
  setShowShareModal: (arg0: boolean) => void;
  setShowRemoveFavoritesModal: (arg0: boolean) => void;
  handleAddingFavoriteListing: (arg0: any) => void;
  handleDownloadFlyer: () => void;
  disableDownloadFlyerButton: boolean;
  marketDescription: AttributeValueType;
  market: AttributeValueType;
  listingId: string;
}
const ListingDetailsContentDesktop: React.FC<ListingDetailsContentDesktopProps> = ({
  data,
  isRouteHistoryStackEmpty,
  isSaved,
  setShowShareModal,
  setShowRemoveFavoritesModal,
  handleAddingFavoriteListing,
  handleDownloadFlyer,
  disableDownloadFlyerButton,
  marketDescription,
  market,
  listingId,
}) => {
  return (
    <div>
      {data?.map(
        (listing: { listingId: any; templateType: any; children: any; propertyType: string }) => {
          return (
            <Fragment key={`${listing.listingId}-${listing.templateType}`}>
              <ListingInfoElementsWrapper
                listingChildren={listing.children}
                propertyType={listing.propertyType}
                isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
                isSaved={isSaved}
                setShowShareModal={setShowShareModal}
                setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
                handleAddingFavoriteListing={handleAddingFavoriteListing}
                handleDownloadFlyer={handleDownloadFlyer}
                disableDownloadFlyerButton={disableDownloadFlyerButton}
                marketDescription={marketDescription}
                market={market}
                listingId={listingId}
              />
            </Fragment>
          );
        },
      )}
    </div>
  );
};

export default ListingDetailsContentDesktop;
