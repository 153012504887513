import React, { useState } from 'react';

import AssetsCarousel from '@components/AssetsCarousel';
import Thumbnail from '@components/Thumbnail/Thumbnail';
import { Typography } from '@components/Typography';
import { formatNumberWithCommas } from '@/utilities/textHelpers';
import { Icon, IconName } from '@components/Icon';

export interface ThumbnailWrapperProps {
  assetUrls: any;
  thumbnailUrl: string;
}

const ThumbnailWrapper: React.FC<ThumbnailWrapperProps> = ({ assetUrls, thumbnailUrl }) => {
  const [showAssetsCarouselModal, setShowAssetsCarouselModal] = useState(false);

  const heroImageUrl = thumbnailUrl === '--' ? undefined : thumbnailUrl;
  const filteredAssetUrls = assetUrls;
  const assetCount = filteredAssetUrls.assets.length;
  const customMediaBanner = (
    <div className="absolute flex left-[4px] top-[2px] w-[55px] justify-between	items-center h-[20px]; text-white-100">
      <Icon classNames="!h-[0.731rem] !w-[0.813rem]" name={IconName.CAMERA_ICON} />
      <Typography variant="body-2" className="text-black ">
        {filteredAssetUrls.imageCount}
      </Typography>
      <Icon classNames="!h-[0.731rem] !w-[0.813rem]" name={IconName.VIDEO_ICON} />
      <Typography variant="body-2" className="text-black ">
        {filteredAssetUrls.videoCount}
      </Typography>
    </div>
  );
  return (
    <>
      <div className="relative overflow-hidden rounded-lg group">
        <Thumbnail imgClassNames="h-[15.5rem]" propertyImageUrl={heroImageUrl} />
        <div
          role="none"
          className="absolute bottom-4 left-6 z-10 rounded-sm px-6 py-3 bg-white-100 border-slate-300 !h-[20px] !w-[62px]">
          {customMediaBanner}
        </div>
        {!!assetCount && assetCount > 0 && (
          <div
            className="absolute top-0 z-10 items-center justify-center hidden w-full h-full cursor-pointer group-hover:flex bg-slate-500 bg-opacity-60"
            onClick={() => setShowAssetsCarouselModal(true)}>
            <div className="px-5 py-3 border rounded-full bg-white-100 border-slate-300">
              <Typography
                className="text-slate-500"
                variant="button">{`View Gallery (${formatNumberWithCommas(
                assetCount,
              )})`}</Typography>
            </div>
          </div>
        )}
      </div>

      {assetCount > 0 && showAssetsCarouselModal && (
        <AssetsCarousel
          assets={filteredAssetUrls.assets}
          onClose={() => setShowAssetsCarouselModal(false)}
          displayCustomMediaButton={true}
          customMediaButton={customMediaBanner}
        />
      )}
    </>
  );
};

export default ThumbnailWrapper;
