import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import { Favorite } from '@/types';
import Button, { ButtonVariant } from '@components/Button';
import FavoritesTable from '@components/FavoritesTable';
import Footer from '@components/Footer';
import { Icon, IconName } from '@components/Icon';
import PostFavoritedModals from '@components/PostFavoritedModals';
import { RemoveFavoritesModal } from '@components/RemoveFavoritesModal';
import RemoveUnavailableListingsModal from '@components/RemoveUnavailableListingsModal/RemoveUnavailableListingsModal';
import SavedContentPageWrapper from '@components/SavedContentPageWrapper';
import { ShareListingModal } from '@components/ShareListingModal';
import { Typography } from '@components/Typography';
import Pagination from '@components/Pagination/Pagination';

import './Favorites.css';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import Sort from '@components/Sort';
import { FilterOption } from '@components/Sort/Sort.types';
import { sortNumericValues } from '@components/SearchResults/SearchResults.utils';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import { useMediaQuery } from 'react-responsive';
import CreateReportModal from '@components/CreateReportModal';
import { getPaginatedFavorites, getAllFavorites } from '@/api/favorites';
import { marketplaceApiInstance } from '@/lib/axios';

const A_TO_Z = 'aToZ';
const Z_TO_A = 'zToA';
const SQF_I_X = 'SiTox';
const SQF_X_I = 'SxToi';
const filterOptions: FilterOption[] = [
  {
    label: 'Name (A - Z)',
    value: A_TO_Z,
  },
  {
    label: 'Name (Z - A)',
    value: Z_TO_A,
  },

  {
    label: 'Square Footage (Min - Max)',
    value: SQF_I_X,
  },
  {
    label: 'Square Footage (Max - Min)',
    value: SQF_X_I,
  },
];

export const Favorites: React.FC = () => {
  const { favorites, isFavoritesLoading, removeFavorites, refetchFavorites } = useUserFavorites();
  const [sortedFavorites, setSortedFavorites] = useState<Favorite[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterOption | undefined>(filterOptions[0]);
  const [contextMenuFavorite, setContextMenuFavorite] = useState<Favorite | null>(null);
  const [selectedFavorites, setSelectedFavorites] = useState<Favorite[]>([]);
  const [unavailableListings, setUnavailableListings] = useState<Favorite[]>([]);
  const [showCollectionsModal, setShowCollectionsModal] = useState(false);
  const [showGenerateAvailabilityReportModal, setShowGenerateAvailabilityReportModal] =
    useState(false);
  const [showRemoveFavoritesModal, setShowRemoveFavoritesModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUnavailableListingsModal, setShowUnavailableListingsModal] = useState(false);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalFavoriteCount, setTotalFavoriteCount] = useState(0);
  const [paginatedFavorites, setPaginatedFavorites] = useState<Favorite[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isPaginatedFavoritesLoading, setIsPaginatedFavoritesLoading] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [allFavorite, setAllFavorite] = useState<Favorite[]>([]);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [removeSingleRow, setRemoveSingleRow] = useState(false);
  const [selectedFavoriteListings, setSelectedFavoriteListings] = useState<string[]>([]);
  const debounceTimeout = useRef<any>(null);
  const isBigScreen = useIsBigScreen();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });
  const menuItemClassNames = 'flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100';

  const { getFlyer } = useApiClient();
  const { addToast } = useToast();

  const handleDownloadFlyer = async (favorite: Favorite) => {
    const data = await getFlyer(favorite.listingId);

    const link = document.createElement('a');

    link.href = encodeURI(URL.createObjectURL(data));
    link.download = `flyer-${favorite.listingId}-${new Date().getTime()}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));

    addToast({
      id: `download-flyer-${new Date().getTime()}`,
      description: `Listing Flyer Downloaded`,
      title: 'Success',
      type: 'success',
    });
  };

  const getSelectedFavoritesIds = (selectedFavorites: Favorite[]) =>
    selectedFavorites.map((favorite) => favorite.favoriteId);

  const handleConfirmRemoveUnavailableListings = async () => {
    await removeFavorites(unavailableListings.map((x) => x.listingId));
    setShowUnavailableListingsModal(false);
  };

  useEffect(() => {
    (async function () {
      await refetchFavorites();
    })();
  }, []);

  const handlePageSizeChange = (newSize: number) => {
    setSelectedPageNumber(1);
    setPageSize(newSize);
  };

  useEffect(() => {
    const notAvailable = favorites.filter((favorite) => !favorite.isVisible);
    setUnavailableListings(notAvailable);
  }, [favorites]);

  useEffect(() => {
    setIsPaginatedFavoritesLoading(true);
    const delayedFunction = () => {
      getPaginatedFavorites(marketplaceApiInstance, {
        pageNumber: selectedPageNumber,
        pageSize: pageSize,
        searchQuery: searchQuery,
      }).then((data: any) => {
        if (data.status === 200) {
          setTotalFavoriteCount(data.data.totalCount);
          setPaginatedFavorites(data.data.favorites);
          setIsPaginatedFavoritesLoading(false);
        }
      });
    };
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(delayedFunction, 1000);
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    setIsPaginatedFavoritesLoading(true);
    getPaginatedFavorites(marketplaceApiInstance, {
      pageNumber: selectedPageNumber,
      pageSize: pageSize,
      searchQuery: searchQuery,
    }).then((data: any) => {
      if (data.status === 200) {
        setTotalFavoriteCount(data.data.totalCount);
        setPaginatedFavorites(data.data.favorites);
        setIsPaginatedFavoritesLoading(false);
      }
    });
  }, [selectedPageNumber, pageSize, favorites]);

  useEffect(() => {
    if (isSelectAll) {
      getAllFavorites(marketplaceApiInstance, searchQuery).then((data: any) => {
        if (data.status === 200) {
          setAllFavorite(data.data);
        }
      });
    }
  }, [isSelectAll]);

  useEffect(() => {
    const FilterValue = selectedFilter?.value;
    let result = [...favorites]; // Create a shallow copy of the original listings array
    switch (FilterValue) {
      case A_TO_Z:
        result.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case Z_TO_A:
        result.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case SQF_I_X:
        result.sort((a, b) => sortNumericValues(a?.sortableSize, b?.sortableSize));
        break;
      case SQF_X_I:
        result.sort((a, b) => sortNumericValues(b?.sortableSize, a?.sortableSize));
        break;
      case 'Default':
        result;
        break;
      default:
        break;
    }
    setSortedFavorites(result);
  }, [selectedFilter, favorites]);

  return (
    <>
      <SavedContentPageWrapper
        isLoading={isFavoritesLoading}
        isPagination={!isBigScreen ? true : false}>
        <div
          className={cn([
            'flex flex-col justify-between h-[3.125rem] sm:h-[3.125rem]',
            unavailableListings.length > 0 ? 'mb-0' : 'mb-4 lg:mb-6',
          ])}>
          <div className="flex items-center justify-between h-[3.125rem]">
            <Typography className="title" variant="title">
              {isBigScreen ? 'My Favorited Listings' : `My Favorites (${favorites.length})`}
            </Typography>
            {!isBigScreen && favorites.length > 0 && (
              <Sort
                name="sort favorites"
                filterOptions={filterOptions}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                customButtonLabel="Sort"
                showCustomButtonLabel={isSmallScreen}
              />
            )}
          </div>
        </div>

        {unavailableListings.length > 0 && (
          <Button
            buttonClassNames="!text-rust-100 hover:!text-rust-300"
            Icon={<Icon name={IconName.ALERT_TRIANGLE} />}
            label={`Remove (${unavailableListings.length}) Unavailable Listings`}
            onClick={() => setShowUnavailableListingsModal(true)}
            variant={ButtonVariant.NO_FILL}
          />
        )}
        {isFavoritesLoading || (favorites.length > 0 && sortedFavorites.length == 0) ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <FavoritesTable
            data={paginatedFavorites}
            allFavorites={favorites}
            onSelectionChange={(favorites) => setSelectedFavorites(favorites)}
            onAddToCollection={() => setShowCollectionsModal(true)}
            onRemove={() => setShowRemoveFavoritesModal(true)}
            onCreateReport={() => setShowGenerateAvailabilityReportModal(true)}
            numberOfSelectedFavorites={selectedFavorites.length}
            selectedPageNumber={selectedPageNumber}
            pageSize={pageSize}
            updatePageSize={(size) => handlePageSizeChange(size)}
            updateSelectedPageNumber={(page) => setSelectedPageNumber(page)}
            totalFavoriteCount={totalFavoriteCount}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            isPaginatedFavoritesLoading={isPaginatedFavoritesLoading}
            setIsPaginatedFavoritesLoading={setIsPaginatedFavoritesLoading}
            isSelectAll={isSelectAll}
            setIsSelectAll={(value) => setIsSelectAll(value)}
            selectedListings={selectedListings}
            setSelectedListings={(selectedListings) => setSelectedListings(selectedListings)}
            selectedFavoriteListings={selectedFavoriteListings}
            setSelectedFavoriteListings={(selectedFavoriteListings) =>
              setSelectedFavoriteListings(selectedFavoriteListings)
            }
            moreActionsCell={({ row }) => {
              return (
                <Menu
                  menuClassName="rounded-lg bg-white-400 shadow w-[14.0625rem] px-5 py-2 sm:!left-[-13.125rem] md:!left-[-26rem] lg:!left-[-13.725rem] sm-top"
                  menuButton={
                    <MenuButton>
                      <Icon
                        classNames="text-white-100 md:text-cement-500"
                        name={IconName.MORE_VERTICAL}
                      />
                    </MenuButton>
                  }>
                  {row.original.isVisible && (
                    <>
                      <MenuItem
                        className={menuItemClassNames}
                        onClick={() => {
                          handleDownloadFlyer(row.original);
                        }}>
                        <Icon name={IconName.DOWNLOAD} />
                        <Typography variant="button">Download Flyer</Typography>
                      </MenuItem>
                      <MenuItem
                        className={menuItemClassNames}
                        onClick={() => {
                          setRemoveSingleRow(true);
                          setContextMenuFavorite(row.original);
                          setShowCollectionsModal(true);
                        }}>
                        <Icon name={IconName.ADD_FOLDER} />
                        <Typography variant="button">Add to Collection</Typography>
                      </MenuItem>
                      <MenuItem
                        className={menuItemClassNames}
                        onClick={() => {
                          setContextMenuFavorite(row.original);
                          setShowShareModal(true);
                        }}>
                        <Icon name={IconName.EXTERNAL_LINK} />
                        <Typography variant="button">Share Listing</Typography>
                      </MenuItem>
                    </>
                  )}
                  <MenuItem
                    className={menuItemClassNames}
                    onClick={() => {
                      setContextMenuFavorite(row.original);
                      setRemoveSingleRow(true);
                      setShowRemoveFavoritesModal(true);
                    }}>
                    <Icon name={IconName.TRASH} />
                    <Typography variant="button">Remove from Favorites</Typography>
                  </MenuItem>
                </Menu>
              );
            }}
          />
        )}
      </SavedContentPageWrapper>

      {showRemoveFavoritesModal && (
        <RemoveFavoritesModal
          show={showRemoveFavoritesModal}
          favorites={contextMenuFavorite ? [contextMenuFavorite] : selectedFavorites}
          onClose={() => {
            setContextMenuFavorite(null);
            setShowRemoveFavoritesModal(false);
            setRemoveSingleRow(false);
          }}
          selectedListings={selectedListings}
          allFavorite={allFavorite}
          setSelectedListings={(selectedListings) => setSelectedListings(selectedListings)}
          setSelectedFavoriteListings={(selectedFavoriteListings) =>
            setSelectedFavoriteListings(selectedFavoriteListings)
          }
          removeSingleRow={removeSingleRow}
          setRemoveSingleRow={(removeSingleRow) => setRemoveSingleRow(removeSingleRow)}
          isSelectAll={isSelectAll}
          setIsSelectAll={(value) => setIsSelectAll(value)}
          setSelectedPageNumber={(selectedPageNumber) => setSelectedPageNumber(selectedPageNumber)}
        />
      )}

      {showGenerateAvailabilityReportModal && (
        <CreateReportModal
          listingGroups={[
            {
              listingIds:
                isSelectAll && allFavorite.length > 0
                  ? allFavorite.map((x) => x.listingId)
                  : selectedListings.length > 0
                  ? selectedListings
                  : sortedFavorites.map((x) => x.listingId),
            },
          ]}
          onConfirm={() => {
            setShowGenerateAvailabilityReportModal(false);
          }}
          onClose={() => {
            setShowGenerateAvailabilityReportModal(false);
          }}
          fromCollections={false}
        />
      )}

      {showCollectionsModal && (
        <PostFavoritedModals
          addToCollectionFlow
          favoriteListingIds={
            isSelectAll && allFavorite.length > 0 && !removeSingleRow
              ? getSelectedFavoritesIds(allFavorite)
              : contextMenuFavorite && removeSingleRow
              ? [contextMenuFavorite.favoriteId]
              : selectedFavoriteListings
          }
          onClose={() => {
            setContextMenuFavorite(null);
            setShowCollectionsModal(false);
            setRemoveSingleRow(false);
          }}
        />
      )}

      {showShareModal && contextMenuFavorite && (
        <ShareListingModal
          listingId={contextMenuFavorite.listingId}
          propertyType={contextMenuFavorite.propertyType}
          show={showShareModal}
          onClose={() => {
            setContextMenuFavorite(null);
            setShowShareModal(false);
          }}
        />
      )}

      {showUnavailableListingsModal && (
        <RemoveUnavailableListingsModal
          unavailableListings={unavailableListings}
          onClose={() => setShowUnavailableListingsModal(false)}
          onConfirm={handleConfirmRemoveUnavailableListings}
        />
      )}

      {!isBigScreen && paginatedFavorites.length > 0 && (
        <Pagination
          selectedPageNumber={selectedPageNumber}
          pageSize={pageSize}
          updatePageSize={(size) => handlePageSizeChange(size)}
          updateSelectedPageNumber={(page) => setSelectedPageNumber(page)}
          totalRows={totalFavoriteCount}
          dataCount={paginatedFavorites.length}
        />
      )}
      <Footer />
    </>
  );
};

export default Favorites;
