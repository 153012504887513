import { DropdownSelectorOption } from '@components/Inputs';

export const availableSqFtOptions: DropdownSelectorOption[] = [
  {
    label: '0 - 25,000 SF',
    value: '25000',
  },
  {
    label: '25,000 - 75,000 SF',
    value: '25000-75000',
  },
  {
    label: '75,000 - 150,000 SF',
    value: '75000-150000',
  },
  {
    label: '150,000 - 300,000 SF',
    value: '150000-300000',
  },
  {
    label: '300,000 - 500,000 SF',
    value: '300000-500000',
  },
  {
    label: '500,000+ SF',
    value: '500000',
  },
];
