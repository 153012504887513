import { Listing } from '@/types';
import { shortenNumber } from '@/utilities/textHelpers';
import SearchResultCard from '@components/SearchResultCard';
import React, { forwardRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import './SearchResults.css';
import { SearchResultListProps } from './SearchResults.types';
import { formatListingId } from './SearchResults.utils';

const SearchResultList: React.FC<SearchResultListProps> = ({
  currentResults,
  height,
  listRef,
  width,
  handleRemoveFavorite,
}) => {
  const navigate = useNavigate();

  const handleResultSelect = (id: string) => navigate(`/listing/${id}`);

  const RowElement = ({ data, index, style }: ListChildComponentProps<Listing[]>) => {
    const key = formatListingId(data[index].id as string);
    const property = data[index].properties;

    const unitOfMeasure = 'SF';
    const { unit_max_square_footage_available, unit_min_square_footage_available } = property;
    let propertySize;

    if (unit_max_square_footage_available && unit_min_square_footage_available) {
      const value =
        unit_max_square_footage_available === unit_min_square_footage_available
          ? unit_max_square_footage_available
          : `${shortenNumber(unit_min_square_footage_available)}-${shortenNumber(
              unit_max_square_footage_available,
            )}`;

      propertySize = { value, unitOfMeasure };
    }

    return (
      <div key={key} id={key} style={style} className="list-item-prop w-[100%]">
        <SearchResultCard
          acreage={property.acreage ?? undefined}
          address={property.full_address}
          clearHeight={property.clear_height ?? undefined}
          dateAvailable={property.date_available ?? undefined}
          dockDoors={property.unit_dock_doors ?? undefined}
          driveInDoors={property.unit_drive_in_doors ?? undefined}
          id={data[index].id as string}
          imageUrl={property.thumbnail_image_url ?? undefined}
          key={key}
          buildingStatus={property.building_status ?? undefined}
          onResultSelect={handleResultSelect}
          propertySize={propertySize}
          propertyType={property.property_type}
          handleRemoveFavorite={handleRemoveFavorite}
          propertyName={property.name}
          unitName={property.unit_name}
          listingViewCount={property.listing_views}
        />
      </div>
    );
  };

  const conversionFactor = parseFloat(getComputedStyle(document.documentElement).fontSize) / 16;

  const innerElementType = forwardRef(({ style, ...rest }: any, ref) => {
    return <div ref={ref} style={style} {...rest} />;
  });

  innerElementType.displayName = 'propertyListingWrapper';

  // Code here used to scroll to selected listing when listings element is first rendered but removed in MAR-3194.

  return (
    <List
      className="List scroll-smooth"
      itemData={currentResults}
      height={height}
      itemCount={currentResults.length}
      itemKey={(index, data) => data[index]?.id || index}
      itemSize={(useMediaQuery({ query: '(min-width: 1024px)' }) ? 160 : 290) * conversionFactor}
      innerElementType={innerElementType}
      overscanCount={20}
      ref={listRef}
      width={width}>
      {RowElement}
    </List>
  );
};

export default SearchResultList;
